import logo from './logo.svg';
import './App.css';

import Header from './Components/Header';
import UpdateNumbers from './Components/UpdateNumbers';
import Router from './Components/Router';

function App() {
  return (
    <>
    
    <Router/>
      
     
    </>
  );
}

export default App;
