
import { useEffect, useState } from "react";

import Swal from "sweetalert2"

const UpdatePhoneNumber = () => {

    const [users, setUsers] = useState([])
    const [stringUser, setStringUser] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [loading, setLoading] = useState(false)
    const requestGetUsers = async () => {


        let myHeaders2 = new Headers();
        myHeaders2.append("Content-Type", "application/json");
        myHeaders2.append("Authorization", localStorage.getItem("id_token"));

        let requestOptions2 = {
            method: 'GET',
            headers: myHeaders2,
            redirect: 'follow'
        };

        await fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/getagentsinfo", requestOptions2)
            .then(response => response.json())
            .then(result => {
                //console.log(result)
                setUsers(result)
                setLoading(true)
            })
            .catch(error => console.log('error', error));


    }

    //console.log(users[0].Email.S)
    useEffect(() => {

        requestGetUsers()

    }, [])

    const validate = () => {
        if (stringUser == "" || phoneNumber == "") {
            Swal.fire('Warning', "Please make sure you filled every input", "warning")
        } else {
            updateInfo()
        }
    }

    const updateInfo = () => {
        console.log(stringUser)
        console.log(phoneNumber)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", localStorage.getItem("id_token"));
        var raw = JSON.stringify({
            "Email": stringUser,
            "Phone": phoneNumber
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/posthpioncall", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                if (result.includes("Attributes")) {
                    Swal.fire('Success', `The number has been updated`, "success")
                }
            })
            .catch(error => console.log('error', error));
    }

    return (

        <div className="margin-auto mt-5">
            <h1>Update a phone number</h1>
            <hr />


            <div className="div-add-phone-number">

                <label className="mb-1 mt-1">Agent email</label>

                {loading &&

                    <select value={stringUser} className="form-select" onChange={(e) => { setStringUser(e.target.value.trim()) }}>
                        <option value='' disabled>---</option>
                        {users.map((user, index) => <option key={user.Email.S} value={user.Email.S}>{user.Email.S}</option>)}
                    </select>
                }

                <br />
                <label className="mb-1 mt-1" htmlFor="txtPhoneNumber">New phone number</label>
                <input type="text" id="txtPhoneNumber" className="form-control" name="txtPhoneNumber" value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }}></input>
                <button onClick={validate} className="btn btn-primary float-right mt-3">Update</button>

            </div>

        </div>
    );
}

export default UpdatePhoneNumber;