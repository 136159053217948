import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddPhone from "./AddPhone";
import DeleteAgent from "./DeleteAgent";
import Header from "./Header";
import SelectAgents from "./SelectAgents";
import UpdateNumbers from "./UpdateNumbers";
import UpdatePhoneNumber from "./UpdatePhoneNumber";

const Router = () => {
    return (
        <div className="display-flex">

            <BrowserRouter>
                <Header />

                <Routes>
                    <Route path="/" element={<SelectAgents />}></Route>
                    <Route path="/UpdateNumbers" element={<UpdateNumbers />}></Route>
                    <Route path="/UpdatePhoneNumber" element={<UpdatePhoneNumber />}></Route>
                    <Route path="/AddNewAgent" element={<AddPhone />}></Route>
                    <Route path="/Delete" element={<DeleteAgent />}></Route>
                </Routes>




            </BrowserRouter>
        </div>

    );
}

export default Router;