
import { useEffect, useState } from "react";
import Spinner from "./Spinner";

import Swal from "sweetalert2"


const UpdateNumbers = () => {

  let headersdata = ["Dates", 'Agent 1 (12:00am - 7:00am)', 'Agent 2 (12:00am - 7:00am)', 'Agent 1 (7:00am - 07:00pm)', 'Agent 2 (7:00am - 07:00pm)', 'Agent 1 (7:00pm - 12:00am)', 'Agent 2 (7:00pm - 12:00am)'];

  let xmlhttp, myObj, myObj2, x, txt = "";

  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoadingPost, setLoadingPost] = useState(false)
  useEffect(() => {
 
    let myHeaders2 = new Headers();
    myHeaders2.append("Authorization", localStorage.getItem("id_token"));
    myHeaders2.append("Content-Type", "application/json");
    let requestOptions = {
        method: 'GET',
        headers: myHeaders2,
        redirect: 'follow'
    };
    fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/schedule", requestOptions)
      .then(response => response.json())
      .then(json => {
        //console.log(json)

        setResults(json)
        setLoading(true)
      }
      )
      .catch(error => console.log('error', error));


 
  }, [])

  // console.log(results)

  // console.log(results.years)

  // console.log(results.alldata)




  function GenerateTable() {
    //Build an array containing Customer records.
    let customers = results.alldata;
    console.log(headersdata.length)
    //console.log(customers[0].Schedule.S)
    


    //Create a HTML Table element.
    let table = document.createElement("TABLE");
    table.classList.add("table-bordered")
    table.border = "1";

    let dayis = 1


    //Get the count of columns.
    let columnCount = headersdata.length;
    
    //Add the header row.
    let row = table.insertRow(-1);
    for (let i = 0; i < columnCount; i++) {
      let headerCell = document.createElement("th");
      headerCell.setAttribute("id", headersdata[i])
      headerCell.innerHTML = headersdata[i];
      row.appendChild(headerCell);
    }



    //Add the data rows.
    for (let i = 0; i < customers.length; i++) {
      if (customers[i]['Month']['N'] == document.getElementById("months").value && customers[i]['Year']['N'] == document.getElementById("Year").value) {

        row = table.insertRow(-1);
        row.setAttribute("id",   dayis + "/" + customers[i]['Month']['N']+"/" + document.getElementById("Year").value)
        let cell = row.insertCell(-1);


        cell.innerHTML = dayis + "/" + customers[i]['Month']['N']+"/"  + document.getElementById("Year").value;
        dayis += 1;
        for (let j = 0; j < columnCount; j++) {


        }
      }
    }


    let dvTable = document.getElementById("dvTable");
    dvTable.innerHTML = "";
    dvTable.appendChild(table);

    for (let i = 0; i < customers.length; i++) {
      if (customers[i]['Month']['N'] == document.getElementById("months").value && customers[i]['Year']['N'] == document.getElementById("Year").value) {
        addcells(customers[i]['Schedule']['S'])

      }
    }
    current(headersdata)
  };



  function addcells(usertoaddcells) {
    for (let columns = 0; columns < headersdata.length -1; columns++) {
      let row = document.getElementById(usertoaddcells);

      let x = row.insertCell(-1);
      x.innerHTML = updatestable(usertoaddcells, columns);

    }

  }


  function updatestable(usertoaddcells, columns) {
    txt = ""
    
    txt += "<input id = " + usertoaddcells + " class = " + columns + "\>";

    txt += "</input>"

    return txt
  }

  // function updatestable(usertoaddcells, columns) {
  //   txt = ""
    
  //   txt += "<input id = " + usertoaddcells + " class = " + columns + "\>";

  //   txt += "</input>"

  //   return txt
  // }


  function current(headersdata) {
    let updater = results.alldata
    //console.log(updater)
    for (let y = 0; y < updater.length; y++) {
      if (updater[y]['Month']['N'] == document.getElementById("months").value && updater[y]['Year']['N'] == document.getElementById("Year").value) {

        for (let tabls = 1; tabls < headersdata.length; tabls++) {
          let element = document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']

          if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 0) {

            element.value = updater[y]['agent1']['S']

          } else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 1) {
            element.value = updater[y]['agent2']['S']

          } else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 2) {
            element.value = updater[y]['agent3']['S']

          } else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 3) {
            element.value = updater[y]['agent4']['S']

          }else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 4) {
            element.value = updater[y]['agent5']['S']

        }else if (document.getElementById(updater[y]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 5) {
            element.value = updater[y]['agent6']['S']

        }

        }
      }
    }
  }




  function ClearMonth() {
    document.getElementById("months").value = "Select Month"
  }
  //console.log(results)


  function getvaluess() {
    let data = [];
    let customers = results.alldata;
    for (let i = 0; i < customers.length; i++) {
        if (customers[i]['Month']['N'] == document.getElementById("months").value && customers[i]['Year']['N'] == document.getElementById("Year").value) {
  
            for (let tabls = 1; tabls < headersdata.length; tabls++) {
  
  
  
                if (document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 0)
                    data.push({
                        Date: customers[i]['Schedule']['S'],
                        agent1: document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild'].value
  
                    });
  
  
                if (document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 1)
                    data.push({
                        Date: customers[i]['Schedule']['S'],
                        agent2: document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild'].value
  
                    });
  
  
                if (document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 2)
                    data.push({
                        Date: customers[i]['Schedule']['S'],
                        agent3: document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild'].value
  
                    });
  
                if (document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 3)
                        data.push({
                            Date: customers[i]['Schedule']['S'],
                            agent4: document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild'].value
  
                        });


                        if (document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 4)
                        data.push({
                            Date: customers[i]['Schedule']['S'],
                            agent5: document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild'].value

                        });

                        if (document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild']['className'] == 5)
                        data.push({
                            Date: customers[i]['Schedule']['S'],
                            agent6: document.getElementById(customers[i]['Schedule']['S']).cells[tabls]['lastChild'].value

                        });

  
            }
        }
    }
  
    return data
  }


 
  function sendittoserver() {
    setLoadingPost(true)
    //console.log("Hola estoy en select agents ")
    let json = getvaluess()
    console.log(json)
    // console.log("hola " + json[0].Date)

    // console.log("hola " + json[0].agent1)

       

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("id_token"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ json });


    var requestOptions = {
        method: 'POST',
        headers: {"Authorization": localStorage.getItem("id_token"),
        "Content-Type": "application/json"
    },
        body: raw,
        redirect: 'follow'
    };

    fetch("https://s1obgtk184.execute-api.eu-central-1.amazonaws.com/prod/schedule", requestOptions)
        .then(response => response.text())
        .then(result => { Swal.fire("The data has been added in the system")
            setLoadingPost(false)
        })
        .catch(error => console.log('error', error));
    

  }



  return (

<div className="margin-auto">
<div>
 
  <h2 className="title">Please select the month and year to build the table</h2>
  
</div>
{
       loading &&
          <div className="select-div">
          <select id="Year" className="form-select" onChange={ClearMonth}>
            <option value='' disabled>---</option>
            {(results.years.map(year => <option key={year} value={year}>{year}</option>))}

          </select>

          <select id="months" className="form-select" onChange={GenerateTable}>
            <option value='' disabled>---</option>
            {(results.months.map(months => <option key={months} value={months}>{months}</option>))}

          </select>
        </div>
   
       
      }
     

      <div id="Deploy">
      <button onClick={sendittoserver} className="btn btn-success float-right mb-3 mt-3">Deploy Schedules</button>
      

      {isLoadingPost && <>
        <br/>
        <br/>
            <Spinner/>
            <br/>
            <br/>
            </>}
      </div>
      
      {/* <button onClick="sendittoserver()">Deploy Schedules</button> */}


    




      <div id="dvTable" className="divtable-class mb-5"></div>

    

    
</div>
  );
}

export default UpdateNumbers;